import { graphql, Link, PageProps } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import Shell from "../layout/shell";

import * as styles from "./work.module.scss";

const Work: React.FC<PageProps<Queries.WorkPageQuery>> = (pageProps) => {
  const caseStudiesData = pageProps.data.caseStudies.nodes;
  if (!caseStudiesData) {
    return null;
  }

  return (
    <Shell>
      <div className="fixed-width">
        <h1>My work</h1>

        <div className={styles.caseStudies}>
          {caseStudiesData.map((caseStudy) => {
            const coverImage =
              caseStudy.data.cover &&
              getImage(caseStudy.data.cover?.gatsbyImageData);
            return (
              <Link to={`/work/${caseStudy.uid}`}>
                {coverImage && (
                  <GatsbyImage
                    image={coverImage}
                    alt={caseStudy.data.cover.alt || ""}
                  />
                )}
                <h4>{`Case study: ${caseStudy.data.title}`}</h4>
                <p>{caseStudy.data.snippet}</p>
                <div className={styles.learnMoreLink}>Learn more</div>
              </Link>
            );
          })}
        </div>
      </div>
    </Shell>
  );
};

export const query = graphql`
  query WorkPage {
    caseStudies: allPrismicCaseStudy {
      nodes {
        uid
        data {
          title
          snippet
          cover {
            alt
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default Work;
